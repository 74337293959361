import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectGrowthChartData } from './pet.selectors';
import { map } from 'rxjs/operators';

@Injectable()
export class PetFacade {
  public readonly canIncludeGrowthChart$ = this.store
    .select(selectGrowthChartData)
    .pipe(map((growtChartData) => !!growtChartData.chartImage));
  constructor(private readonly store: Store) {}
}
